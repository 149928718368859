import React from 'react';
import Company from 'views/Company';
import { SEO as Head } from 'components/seo';

const CompanyPage = (): JSX.Element => {
  const seo = {
    title: 'About Coreestate tokenized consumer rentals',
    description:
      'Coreestate - Redefining rental properties for all. Embracing blockchain and token-assets, we aim to make housing affordable and accessible to everyone. Join us in our mission to revolutionize the rental property industry, where consumers become property owners.',
    pathname: '/company',
  };
  return (
    <>
      <Head
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
      />
      <Company />
    </>
  );
};

export default CompanyPage;
