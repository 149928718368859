import React from 'react';
import Main from 'layouts/Main';
import {
  AboutSection,
  OurTeamSection,
  AdvisorsSection,
  CareersSection,
  OurPartnersSection,
} from './components';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Divider } from 'utils';

const CompanyView = (): JSX.Element => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  });

  return (
    <Main bgcolor={theme.palette.background.level1}>
      <Divider height={isSmallScreen ? '1em' : '3em'} background="white" />
      <AboutSection />
      <OurTeamSection />
      <AdvisorsSection />
      <OurPartnersSection />
      <CareersSection />
    </Main>
  );
};

export default CompanyView;
